import './App.css';
import { ReactComponent as IconFacebook } from './assets/icons/facebook.svg';
import { ReactComponent as IconInstagram } from './assets/icons/instagram.svg';

function App() {
  return (
    <div className="ComingSoon">
      <header className="ComingSoon-header">
        <h1>WE'RE COMING SOON..</h1>
        <p>Website coming soon. Please check back to know more. Shoot us an email if you're curious.</p>
        <button
          className="MailButton"
          onClick={() => (window.location.href = "mailto:sellfromweb@gmail.com")}
        >
          Send us an email
        </button>
        <div className="social">
          <a href="https://www.facebook.com/share/1DZ3T6bCMe/?mibextid=LQQJ4d" title="Facebook" target="_blank" rel="noopener noreferrer">
            <IconFacebook className="icon" />
          </a>
          &nbsp;&nbsp;
          <a href="https://www.instagram.com/sellfromweb?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" title="Instagram" target="_blank" rel="noopener noreferrer">
            <IconInstagram className="icon" />
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;
